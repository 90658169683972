import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { defaultLocale } from '../config/i18n.config';
import { LocaleProvider } from '../context/localeContext';
import Layout from '../components/Layout';
import AboutPage from './AboutPage';
import Section from '../components/Section';
import NewsletterSignupForm from '../components/NewsletterSignupForm';

const AboutPageTemplate = ({ data, pageContext }) => {
  const { frontmatter: page } = data.markdownRemark;

  return (
    <LocaleProvider locale={pageContext.locale}>
      <Layout>
        <AboutPage
          title={page[pageContext.locale].title || page[defaultLocale].title}
          heroImage={page[defaultLocale].image}
          heroContent={
            page[pageContext.locale].hero || page[defaultLocale].hero
          }
          advisors={
            page[pageContext.locale].advisors || page[defaultLocale].advisors
          }
          funders={page[defaultLocale].funders}
          partners={page[defaultLocale].partners}
        />
        <Section>
          <NewsletterSignupForm />
        </Section>
      </Layout>
    </LocaleProvider>
  );
};

AboutPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPageTemplate;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        en {
          title
          hero
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          advisors {
            name
            organization
            description
          }
          funders {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 256
                  quality: 100
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
            alt
          }
          partners {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 256
                  quality: 100
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
            alt
          }
        }
        de {
          title
          hero
          advisors {
            name
            organization
            description
          }
        }
      }
    }
  }
`;
